import React, { useEffect } from 'react';
import WonkaHome from './components/Home';
import TaskPage from './components/Task';
import RewardsPage from './components/Rewards';
import ReferralPage from './components/Referral';
import WaitlistPage from './components/Waitlist';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";
import { app, analytics } from './firebase'; // Import the Firebase configuration
import axios from 'axios';

const telApp = window.Telegram.WebApp;
const testingMode = process.env.REACT_APP_TESTING_MODE === 'true';

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
    },
  },
});

const ENDPOINT = process.env.REACT_APP_API_URL;

function App() {
  const [isTelegramMiniApp, setIsTelegramMiniApp] = React.useState(false);
  const [userData, setUserData] = React.useState(null);
  const [userProfileInfo, setUserProfileInfo] = React.useState(null);
  const [profileUrl, setProfileUrl] = React.useState(null);
  const [userPlatform, setUserPlatform] = React.useState(null);
  const [openConfetti, setOpenConfetti] = React.useState(false);
  const [startAppId, setStartAppId] = React.useState(null);
  const [loadedAppData, setLoadedAppData] = React.useState(false);

  const init = () => {
    //  change some initial properties
    telApp.ready();    
    telApp.expand();
    telApp.setHeaderColor('#0133F6');

    setUserPlatform(telApp.platform);
    setStartAppId(Number(telApp.initDataUnsafe.start_param));

    var search = telApp.initData;
    var data = null;
    if (search !== null && search !== '') {
       var converted = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) {
         return key === "" ? value : decodeURIComponent(value);
       });
       var data = JSON.parse(converted.user);

      //  if has not username, alert user to add username and then back
       if (!data.username) {
         telApp.showAlert('Please add username to your telegram account then back!');
         telApp.close();
       }
   } 
   else {
       var data = {
         "id": 1887509957,
         "first_name": "Its Justin",
         "last_name": "",
         "username": "P2P_JS",
         "language_code": "en",
         "is_premium": true,
         "allows_write_to_pm": true
     }
   }
    if (data !== null) {
      setUserData(data)
      setIsTelegramMiniApp(true);
    } else {
       setIsTelegramMiniApp(false);
    }
  }

  const handleSignup = async () => {
    await axios.post(`${ENDPOINT}/signup`, {
      userId: userData.id,
      username: userData.username,
      firstname: userData.first_name,
      lastname: userData.last_name,
      startAppId: startAppId
    }) 
    .then(async () => {
      await getProfileInfo();
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      setProfileUrl(`https://app.wonka.tips/api/public/users/avatars/${userData.id}.jpg`);
    })
  }

  const getProfileInfo = async () => {
    await axios.get(`${ENDPOINT}/user/${userData.id}`)
      .then((response) => {
        setUserProfileInfo(response.data);
        setLoadedAppData(true);
      })
      .catch((error) => {
        console.log(error);
        telApp?.showAlert('Failed to get user information data!\nFor support, Please check bot profile bio!');
        telApp.close();
      })
  }

  useEffect(() => {
    init();

    localStorage.setItem('appLoaded', false)
  }, []);

  useEffect(() => {
    if (userData !== null) {
      handleSignup();
    }
  }, [userData]);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
         
          {/* Home Page */}
         <Route path="/" element={<WonkaHome 
             theme={theme} userData={userData} userProfileInfo={userProfileInfo} 
             setUserProfileInfo={setUserProfileInfo} loadedAppData={loadedAppData} setLoadedAppData={setLoadedAppData}
             profileUrl={profileUrl} userPlatform={userPlatform} 
             openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
             telApp={telApp} ENDPOINT={ENDPOINT} />} />
         
          {/* Task Page */}
         <Route path="/task" element={<TaskPage 
            theme={theme} userData={userData} 
            openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
            telApp={telApp} ENDPOINT={ENDPOINT} />} />

          {/* Rewards Page */}
         <Route path="/rewards" element={<RewardsPage
            theme={theme} userData={userData}
            openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
            telApp={telApp} ENDPOINT={ENDPOINT} />} />

            {/* Referral Page */}
         <Route path="/referrals" element={<ReferralPage
            theme={theme} userData={userData} userProfileInfo={userProfileInfo}
            telApp={telApp} ENDPOINT={ENDPOINT} />} />
   
          {/* Waitlist Pages */}
         <Route path="/waitlist" element={<WonkaHome 
             theme={theme} userData={userData}
             profileUrl={profileUrl} userPlatform={userPlatform} 
             openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
             telApp={telApp} ENDPOINT={ENDPOINT} />} />
          
         {/* <Route path="/waitlist" element={<WaitlistPage
            theme={theme} userData={userData}
            openConfetti={openConfetti} setOpenConfetti={setOpenConfetti}
            telApp={telApp} ENDPOINT={ENDPOINT} />} /> */}

      </Routes>
    </ThemeProvider>
  );
}

export default App;