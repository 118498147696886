import React from 'react';
import { Box, Grid, Typography, Avatar, CircularProgress } from '@mui/material';

function FriendsList(props) {
  const { friends, friendsCount, isFetchingFriends, ENDPOINT } = props;

  const handleRanckingEmojis = () => {
    let emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Food%20and%20Drink/Cup%20With%20Straw.webp';
    if (friendsCount === 0) return emojiUrl;

    if (friendsCount <= 3) {
      emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/3rd%20Place%20Medal.webp';
    } else if (friendsCount > 3 && friendsCount <= 6) {
      emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/2nd%20Place%20Medal.webp';
    } else if (friendsCount > 6) {
      emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/1st%20Place%20Medal.webp';
    } else if (friendsCount > 12) {
      emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/Sports%20Medal.webp';
    } else {
      emojiUrl = 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Activity/Military%20Medal.webp';
    }

    return emojiUrl;
  };

  return (
    <Box display="flex" flexDirection={'column'} position={'relative'} bottom={'10vh'} width={'93vw'} height='38vh' sx={{ alignItems: 'center', mb: 1, background: 'dodgerblue', borderRadius: '20px' }}>
      <Grid item xs={12} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" mx={'auto'} mb={2} pt={2}>
        <Typography variant="h6" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" zIndex={1} sx={{ color: 'white' }}>
          {`Friends list (${friendsCount})`}
        </Typography>
        <Box component="img" loading="eager" src={handleRanckingEmojis()} alt="Medal" sx={{ width: friendsCount > 0 ? '15vw' : '35vw', position: 'absolute', top: friendsCount > 0 ? '0vh' : '13vh', right: friendsCount > 0 ? '2vw' : '30vw' }} />
      </Grid>
      <Box display="flex" flexDirection={'row'} flexWrap={'wrap'} justifyContent="center" alignItems="flex-start" width={'101%'} height={'35vh'} overflow={'auto'}>
        {isFetchingFriends ? (
          <CircularProgress size={70} thickness={7} sx={{ color: '#BEB7FF', ml: 4, mt: 3 }} />
        ) : friendsCount > 0 ? (
          friends.slice(0, 25).map((friend, index) => (
            <Box key={index} display="flex" flexDirection={'column'}>
              <Avatar alt={friend.firstname} src={`${ENDPOINT}/public/users/avatars/${friend.userId}.jpg`} sx={{ width: 69, height: 69, mx: 1, border: '3px solid white', borderRadius: '20px' }} />
              <Typography variant="p" fontFamily="Sora, sans-serif" fontWeight="bold" zIndex={1} width={'100%'} ml={2} mb={1.75} mt={0.5} sx={{ color: 'white' }}>
                {friend.firstname.length > 5 ? `${friend.firstname.slice(0, 5)}...` : friend.firstname}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="h5" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" zIndex={1} mt={5} sx={{ color: 'white', px: 2, py: 1, background: 'rgba(0,0,0,0.65)', borderRadius: '15px' }}>
            No any friends!
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default FriendsList;
