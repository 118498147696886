import React from 'react';
import { Box, Typography, Button} from '@mui/material';
import candy from '../../assets/images/gifs/candy.gif';
import { styled } from '@mui/system';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '96%',
  height: '46vh',
//   makeit to linear gradient
  background: 'linear-gradient(180deg, rgb(102 140 252 / 93%) -9%, rgb(0 0 0 / 54%) 100%)',
  backdropFilter: 'blur(10px)',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '25px',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
//   change color of everything to white
  '*': {
    color: 'white',
  },
  zIndex: 999,
}));
 
const PopupImage = styled('img')({
  width: '125px',
  height: '125px',
  position: 'relative',
  bottom: '13px',
  left: '75px',
  transform: 'translateX(50%)',
});


function EarlyUserPopup(props) {
   const { open, onClose, registerDate } = props;


   const sortDate = (date) => {
      // return like 11 January 2022 
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const d = new Date(date);
      return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`;
   }

  return (
    // when touched outside popup will close
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)', }} onClose={onClose}>
      {/* Close button */}
      <Box display="flex" flexDirection="column" justifyContent="center" position='relative' top='15px' >
        <PopupImage src={'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Animals%20and%20Nature/Baby%20Chick.webp'} alt="Wonka" loading='lazy'/>
        <Typography fontFamily="Sora, sans-serif" fontWeight="bolder" fontSize="20px" textAlign="center" sx={{ color: 'white', px: 2, my: 0.5 }}>
           Early User Badge
        </Typography>
        <Typography fontFamily="Sora, sans-serif" fontWeight="bold" fontSize="17px" textAlign="center" sx={{ color: 'white', py: .75, mb: 1, background: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px', width: '90vw', mx: 'auto'}}>
            Congratulations mate! <br/>You got early badge at {sortDate(registerDate)}
        </Typography>
      </Box>

        {/* Send Tip */}
      <Button 
            variant="contained"
            color="primary"
            onClick={onClose}
            sx={{
              position: 'absolute', 
              left: '48vw',
              bottom: '2.5vh',
              transform: 'translate(-50%, 0%)',
              width: '90vw',
              height: '9vh',
              borderRadius: '20px',
              textTransform: 'none',
              fontFamily: 'Sora',
              fontSize: '18px',
              fontWeight: 800,
              '&:hover': {
                border: '3px solid white',
              }
            }}
           >
             Nice
        </Button>
    </PopupContainer>
  );
};

export default EarlyUserPopup;