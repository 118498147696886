import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import GiftIcon from '@mui/icons-material/CardGiftcard';
import CopyAllSharp from '@mui/icons-material/CopyAllSharp';
import { styled } from '@mui/system';
import FriendsList from './Referral/FriendsList';
import ShareLink from './Referral/ShareLink';
import ButtonTabs from './Tabs/Tabs';
import Confetti from './Confetti/Confetti';
import confetti from '../assets/images/gifs/confetti.gif';
import cloud from '../assets/images/cloud-bg.png';
import axios from 'axios';

const PopupImage = styled('img')({
  width: '60vw',
  height: '20vw',
  position: 'absolute',
  top: '7vh',
  left: '50%',
  transform: 'translateX(-50%)',
});

function ReferralsPage(props) {
  const { userData, userProfileInfo, telApp, ENDPOINT } = props;
  const [userId, setUserId] = useState(null);
  const [friends, setFriends] = useState([]); // Default to empty array to avoid undefined issues
  const [friendsCount, setFriendsCount] = useState(0);
  const [isFetchingFriends, setIsFetchingFriends] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [shareMessageLink, setShareMessageLink] = useState('');

  useEffect(() => {
    setInviteLink(createInviteLink(userId));

    const message = `Join Wonka Tips now! 🎉
Get daily allowance and enjoy your free tipping.\n\nClick the link below to get started.\n${createInviteLink(userId)}`;
    setShareMessageLink(createShareMessageLink(message));
  }, [userId]);

  useEffect(() => {
    if (userProfileInfo) {
         const friendsCount = typeof (userProfileInfo.referralsList) === "string" ? 0 : (userProfileInfo.referralsList).length;
         setFriendsCount(friendsCount);
         
         const friends =  typeof (userProfileInfo.referralsList) === "string" ? [] : (userProfileInfo.referralsList).slice(0, 25);
         setFriends(friends);
    } else {
      setIsFetchingFriends(true)
    }
  }, [userProfileInfo, friends]);

  useEffect(() => {
    if (userData) {
      setUserId(userData.id);
    }
  }, [userData]);

  const createInviteLink = (userId) => {
    return `https://t.me/WonkaTipsBot/launch?startapp=${userId}`;
  };

  const createShareMessageLink = (text) => {
    const encodedText = encodeURIComponent(text);
    return `https://t.me/share/url?url=${encodedText}`;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    telApp.showAlert('Invite link copied!');
  };


  return (
    <Box sx={{ height: '100vh', minHeight: '100vh', display: 'flex', backgroundSize: '300px' }}>
      <Grid container justify="center" sx={{ height: '100vh', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'space-between', justifyContent: 'center', alignItems: 'center' }}>
        {/* Top header */}
        <Grid item xs={12} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" height='10vh' sx={{ mt: 10 }}>
          <Typography variant="h5" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" position={'relative'} bottom={'15px'} left={'2px'} letterSpacing={1} zIndex={1} sx={{ color: 'white' }}>Invite friends!</Typography>
          <PopupImage src={cloud} alt="Popup Image" loading='eager' />
        </Grid>

        {/* Top section */}
        <Box display="flex" flexDirection={'row'} justifyContent="center" alignItems="center" position={'relative'} bottom={'5vh'} width={'93vw'} height='13vh' sx={{ alignItems: 'center', mb: 1, background: 'dodgerblue', borderRadius: '20px' }}>
          <Grid item xs={12} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center" mx={'auto'} mb={2} pt={2} pl={2}>
            <Typography variant="p" fontFamily="Sora, sans-serif" fontWeight="bold" zIndex={1} width={'100%'} sx={{ color: 'white' }}>Earn 3% of your friends points each time they advance to a higher league</Typography>
            <Box component="img" loading="lazy" src={'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Food%20and%20Drink/Custard.webp'} alt="Custard" sx={{ width: '15vw', position: 'absolute', top: '6vh', left: '76vw', rotate: '-20deg' }} />
          </Grid>
        </Box>

        {/* Friend list */}
        <FriendsList friends={friends} friendsCount={friendsCount} isFetchingFriends={isFetchingFriends} userId={userId} telApp={telApp} ENDPOINT={ENDPOINT} />

        {/* Share link */}
        <ShareLink inviteLink={inviteLink} shareMessageLink={shareMessageLink} copyToClipboard={copyToClipboard} telApp={telApp} />

        {/* Bottom footer */}
        <ButtonTabs tabValue={4} />
      </Grid>
    </Box>
  );
}

export default ReferralsPage;
