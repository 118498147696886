import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import axios from 'axios';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '96%',
  height: '30vh',
  background: 'linear-gradient(184deg, #698dff, transparent)',
  backdropFilter: 'blur(10px)',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '25px',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
  '*': {
    color: 'white',
  },
  zIndex: 9999,
}));

const StyledTabButton = styled(Button)(({ theme }) => ({
  color: 'white',
  fontSize: '18px', 
  width: '75vw', 
  height: '8vh', 
  borderRadius: '20px', 
  fontFamily: 'Sora, sans-serif', 
  fontWeight: 700, 
  textTransform: 'Capitalize',
  marginTop: '0.5vh',
  backgroundColor: 'royalblue',
  '&:hover': {
    backgroundColor: 'royalblue',
  },
}));

function ConfirmTaskPopup(props) {
  const { 
    task, userId, telApp, open, 
    onClose, setOpenConfetti, ENDPOINT,
    setStopFetchingTasks 
  } = props;

  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [timerDuration, setTimerDuration] = useState(0);
  const [intervalId, setIntervalId] = useState(null);  // Store interval ID to clear it later

  // Determine the required wait time based on task type
  const determineWaitTime = (taskTitle) => {
    const TT2LC = taskTitle.toLowerCase();
    if (TT2LC.includes('subscribe') || TT2LC.includes('follow')) {
      return 10;  // 10 seconds for subscribe tasks
    } else if (TT2LC.includes('watch')) {
      return 40;  // 40 seconds for watch tasks
    } else if (TT2LC.includes('repost')) {
      return 12;  // 12 seconds for repost tasks
    }
    return 0;  // No wait time for other tasks (like Telegram)
  };

  const handleSubButton = async () => {
    window.open(task.link, '_blank');
    const waitTime = determineWaitTime(task.title);

    if (waitTime > 0) {
      setTimerDuration(waitTime);
      setIsTimerActive(true);

      // Start interval timer
      const interval = setInterval(() => {
        setTimerDuration((prev) => {
          if (prev === 1) {
            clearInterval(interval);  // Stop the timer when it reaches zero
            setIsTimerActive(false);
            setIsTaskCompleted(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);  // Decrease timerDuration every second

      setIntervalId(interval);  // Store interval ID to clear it later
    } else {
      setIsTaskCompleted(true);  // No timer needed for Telegram tasks
    }
  };

  const handleCheckButton = async () => {
    if (isTaskCompleted) {
      setStopFetchingTasks(true);

      await axios.get(`${ENDPOINT}/check-task`, { params: { taskId: task.taskId, userId: userId } })
        .then((response) => {
          setOpenConfetti(true);
          onClose();
        })
        .catch((error) => {
          telApp.showAlert('Failed to check task status!\nFor support, Please check bot profile bio!');
          console.log(error);
        })
        .finally(() => {
          setStopFetchingTasks(false);
          setTimeout(() => {
            setOpenConfetti(false);
          }, 1000);
        });
    } else {
      telApp?.showAlert('Please wait until the task is completed.');
    }
  };

  // Clear the interval when the popup is closed to prevent memory leaks
  useEffect(() => {
    if (!open && intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [open, intervalId]);

  return (
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)'}} onClose={onClose}>
      {/* Black overlay */}
      <Box display={open ? 'block' : 'none'} width="200vw" height="210vh" position="absolute" top={0} left={0} right={0} bottom={0} bgcolor="black" sx={{ opacity: '0.5', backdropFilter: 'blur(10px)', transform: 'translate(-50%, -50%)' }} />

      {/* Close button */}
      <Box display="flex" justifyContent="flex-end" p={2} position="absolute" right={0}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box display="flex " flexDirection="column" justifyContent="center">
        <Typography variant="h5" position={"relative"} top={"25px"} fontFamily={"Sora"} fontSize={"20px"} ml={2} sx={{ width: 'max-content' }}>
          {task ? task.title.length > 23 ? `${task.title.slice(0, 23)}...` : task.title : ''}
        </Typography>
      </Box>
      
      {/* Show task buttons */}
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" position="relative" top="6vh">
        <StyledTabButton onClick={handleSubButton} disabled={isTimerActive}>
          {isTimerActive ? `Please wait ${timerDuration} seconds` : 'Start Task'}
        </StyledTabButton>
        <StyledTabButton onClick={handleCheckButton} disabled={!isTaskCompleted}>
          Check Task
        </StyledTabButton>
      </Box>
    </PopupContainer>
  );
}

export default ConfirmTaskPopup;
