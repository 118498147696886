/** @format */

import React, { useState, useCallback, useEffect } from "react";
import { Typography, Button } from "@mui/material";
import "./index.css";

const Wheel = (props) => {
    const [isSpinning, setIsSpinning] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [finalPrize, setFinalPrize] = useState(null);
    const { items, telApp, ENDPOINT } = props;

    const selectItem = useCallback(() => {
        telApp?.showAlert('Spin will be available soon!\nThanks for your patience :)');
        return;

        if (!isSpinning && props.items.length > 0) {
            setIsSpinning(true);

            const spinDuration = 4000; // Duration for spinning
            const totalTurns = 4; // Total full turns
            const itemCount = props.items.length;
            const newItemIndex = Math.floor(Math.random() * itemCount);
            const degreesPerItem = 360 / itemCount;
            const totalDegrees = totalTurns * 360 + (360 - (newItemIndex * degreesPerItem));
            const delayForResult = 4000; // Delay to show result after spinning

            // Rotate the wheel
            document.querySelector('.wheel').style.transition = `transform ${spinDuration}ms ease-out`;
            document.querySelector('.wheel').style.transform = `rotate(${totalDegrees}deg)`;

            // Update selectedItem and show alert after spin duration
            setTimeout(() => {
                setSelectedItem(newItemIndex);
                setFinalPrize(props.items[newItemIndex]); // Update final prize state
                alert(`You win ${props.items[newItemIndex]}!`);
                setIsSpinning(false);
            }, delayForResult);
        }
    }, [isSpinning, props.items]);

    useEffect(() => {
        // Sync final prize with selected item
        if (selectedItem !== null) {
            setFinalPrize(props.items[selectedItem]);
        }
    }, [selectedItem, props.items]);

    const wheelVars = {
        "--nb-item": items.length,
        "--selected-item": selectedItem,
    };
    const spinningClass = isSpinning ? "spinning" : "";

    return (
        <div className="wheel-container">
            <div
                className={`wheel ${spinningClass}`}
                style={wheelVars}
            >
                {items.map((item, index) => (
                    <div
                        className="wheel-item"
                        key={index}
                        style={{ "--item-nb": index }}
                    >
                        {item + ' Points'}
                    </div>
                ))}
                <button
                    className="spin-button"
                    onClick={selectItem}
                    disabled={isSpinning}
                >
                    Spin
                </button>
            </div>
            {finalPrize && !isSpinning && (
                <Typography variant="h6" className="prize" fontSize="1.5rem" fontFamily="Sora, sans-serif" fontWeight="bold"> 
                    You won {finalPrize} Points
                </Typography>
            )}
        </div>
    );
};

export default Wheel;
